.thumbsContainer {
  margin-top: 16px;
  padding: 20px;
  display: flex;
  overflow-x: auto;
}

.uploadThumbsContainer{
  margin-top: 16px;
  padding: 20px;
  display: flex;
}

.thumb {
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 16px;
  width: 120px;
  height: 120px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  min-width: 120px;
}

.thumb:hover {
  opacity: 1;
  transition: .5s ease;
}

.thumb:hover .middle {
  opacity: 0.7;
}

.thumb:hover .textArea {
  opacity: 1;
}

.thumbInner {
  display: flex;
  min-width: 0px;
  overflow: hidden;
  user-select: initial;
  /* web-user-select: initial; */
}

.img {
  display: block;
  width: 100%;
  object-fit: cover;
}

.thumbButton {
  position: absolute;
  right: 10px;
  top: 10px;
  background: #B22222;
  color: #fff;
  border: 0px;
  border-radius: .325em;
  cursor: pointer;
}

.middle {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.textArea {
  background-color: #fff;
  color: black;
  font-size: 16px;
  opacity: 1;
  font-weight: bold;
  padding: 32px 32px;
}

.upload-icon{
  width: 50px;
}

.uploadTextArea {
  color: black;
  font-size: 13px;
  opacity: 1;
  font-weight: bold;
}

.uploadMiddle{
  opacity: 1;
  position: absolute;
  top: 20%;
  left: 30%;
  text-align: center;
}

.uploadThumbInner {
  width: 100%;
  display: flex;
  min-width: 0px;
  overflow: hidden;
  user-select: initial;
  /* web-user-select: initial; */
}

.uploadThumb {
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  width: 120px;
  height: 120px;
  padding: 4px;
  box-sizing: border-box;
  cursor: pointer;
  min-width: 120px;
  opacity: 0.6;
  background: #ededed;
  box-shadow: 1px 1px 4px;
}

.filter-btns .btn{
  padding: 5px !important;
}

.uploadThumbInner:hover  .upload-icon{

  color: #14799e !important;
}

.uploadThumbInner:hover  .uploadTextArea{
  color : #14799e !important;
}

