  .slotHorizontalRow {
    overflow-x: auto;
    overflow-y: hidden;
  }
  
  .slotContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px;
  }
  
  .slotThumb {
    position: relative;
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 16px;
    width: 200px;
    height: 160px;
    padding: 4px;
    /* box-sizing: border-box; */
  }
  
  .slotInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
    user-select: initial;
    /* web-user-select: initial; */
  }
  
  .thumb {
    position: relative;
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 16px;
    width: 120px;
    height: 120px;
    padding: 4px;
    box-sizing: border-box;
    cursor: pointer;
  }
  
  .thumb:hover {
    opacity: 0.5;
    transition: .5s ease;
  }
  
  .thumb:hover .middle {
    opacity: 1;
  }
  
  .thumbInner {
    display: flex;
    min-width: 0px;
    overflow: hidden;
    user-select: initial;
    /* web-user-select: initial; */
  }
  
  .mediaImage {
    display: block;
    width: 100%;
    object-fit: cover;
  }
  
  .thumbButton {
    position: absolute;
    right: 10px;
    top: 10px;
    background: #B22222;
    color: #fff;
    border: 0px;
    border-radius: .325em;
    cursor: pointer;
  }
  
  .middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }
  
  .textArea {
    background-color: #888888;
    color: white;
    font-size: 16px;
    padding: 8px 16px;
  }
